<template>
    <div class='login'>
        <div class="login_bg">
            <img src="../assets/imgs/login_bg.png"/>
        </div>
        <div class="login_content">
            <ul class="login_content_titles">
                <li 
                    v-for="(item, index) in inputTitles" 
                    @click="changeTab(index)"
                    :key="index+item">
                    <p :class="{'border_bottom': index == currentIndex}">{{item}}</p>
                </li>
            </ul>
            <ul class="login_content_input">
                <li class="login_content_input_item" v-for="(item, index) in currentInputs" :key="index">
                    <label :style="bgStyle(icon[index])">{{item.label}}</label>
                    <input :type="item.type" v-model="item.value" :placeholder="item.placeHolder"/>
                    <text v-show="item.value && item.value.length > 0" class="login_content_input_item_close" @click="clearInput(item)">x</text>
                </li>
            </ul>
            <button class="login_content_submit" @click="userLogin">登录</button>
            <a href="tel:021-64175077" class="login_content_tip">登录/注册遇到问题?</a>
        </div>  
    </div>
</template>
<script>
import { computed, inject, reactive, toRefs } from 'vue'
import { login } from '../utils/api'
import { setUserName, isDev, goBack, toast} from '../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             inputItems: [
                 [
                    {label: '账户', placeHolder: '请输入银行卡/投注卡号', value: '', type:'text'},
                    {label: '投注密码', placeHolder: '请输入投注密码', value: '', type: 'password'},
                ],
                 [
                    {label: '用户名', placeHolder: '请输入用户名', value: '', type: 'text'},
                    {label: '登录密码', placeHolder: '请输入密码', value: '', type: 'password'},
                ]
             ],
             icon: [require('../../src/assets/imgs/login_user.png'), require('../../src/assets/imgs/login_pass.png')],
             inputTitles: ['卡号登录', '用户名登录'],
             currentIndex: 0
        })
        const currentInputs = computed(() => {
            return state.inputItems[state.currentIndex]
        })
        if (isDev()) {
            state.inputItems[0][0].value = '0501252'
            state.inputItems[0][1].value = '214620'
        }
        function changeTab(index) {
            state.currentIndex = index
        }
        const setIsLogin = inject('loginStatus').setIsLogin
        async function userLogin() {
            try {
                const values = state.inputItems[state.currentIndex].map(ele => ele.value)
                const params = {
                    checkcode: 'WLGC',
                    loginType: state.currentIndex == 0 ? 2 : 1,
                    uname: values[0],
                    password: values[1]
                }
                const result = await login(params)
                if (result.code == 0) {
                    toast('登录成功')
                    if (result.returnValue) {
                        setUserName(result.returnValue.userName)
                    }
                    setIsLogin(true)
                    goBack()
                }
            } catch(e) {
                console.error(e)
            }
        }
        function clearInput(item) {
            item.value = ''
        }
        function bgStyle(icon) {
            return {
                background: `url(${icon}) left center no-repeat`,
                backgroundSize: '13px'
            }
        }
        return {
            ...toRefs(state),
            currentInputs,
            changeTab,
            userLogin,
            clearInput,
            bgStyle,
        }
    },
}
</script>
<style lang='scss' scoped>
.login {
    .border_bottom {
        border-bottom: solid $theme-dark 3px;
    }
    &_bg {
        img {
            width: 100%;
            height: auto;
        }
    }
    &_content {
        margin: $margin-big 40px;
        &_titles {
            display: flex;
            justify-content: space-between;
            li {
                width: 35%;
                box-sizing: border-box;
                p {
                    padding: 8px 0;  
                    text-align: center;
                }
                &:first-child {
                    position: relative;
                    p {
                         &::after{
                             content: '';
                             position: absolute;
                             width: 1px;
                             height: 25px;
                             right: -50%;
                             top: 50%;
                             transform: translateY(-50%);
                             background: $gray-light;
                         }
                    }
                   
                }
            }
            
        }
        &_input {
            padding-top: 40px;
            &_item {
                border: solid $line-gray 1px;
                border-radius: $box-radius-small;
                margin-bottom: 20px;
                padding: 10px 15px;
                background-color: $bg-gray;
                display: flex;
                align-items: baseline;
                position: relative;
                label {
                    display: inline-block;
                    word-break: keep-all;
                    margin-right: 5px;
                    padding-left: 20px;
                }
                input::-webkit-input-placeholder {
                    color: #ccc;
                    font-size: $font-middle;
                }
                input::-moz-input-placeholder {
                    color: #ccc;
                    font-size: $font-middle;
                }
                input::-ms-input-placeholder {
                    color: #ccc;
                    font-size: $font-middle;
                }
                &_close {
                    position: absolute;
                    display: inline-block;
                    right: 10px;
                    height: 15px;
                    width: 15px;
                    line-height: 12px;
                    text-align: center;
                    border-radius: 50%;
                    font-weight: 400;
                    top: 50%;
                    transform: translateY(-50%);
                    background: rgba(0, 0, 0, 0.25);
                    color:#fff;
                }
            }
        }
        &_submit {
            display: block;
            background-color: $theme-dark;
            width: 100%;
            color: #fff;
            font-size: $font-big;
            border-radius: $box-radius-small;
            padding: 8px 0;
            margin-top: 50px;
        }
        &_tip {
            color: $gray-light;
            margin-top: 40px;
            text-align: center;
            display: block;
        }
    }
}
</style>